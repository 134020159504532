//勋章体系
export const medalList = [
  {
    type: 0,
    name: "新手报道",
    explain: "新人注册后完成验证",
    number: 0,
    mumberMaxLen: 0,
    imgUrl: require("assets/images/medal/xsbd.png"),
  },
  {
    type: 0,
    name: "准备就绪",
    explain: "完善信息",
    number: 0,
    mumberMaxLen: 0,
    imgUrl: require("assets/images/medal/zbjx.png"),
  },
  {
    type: 0,
    name: "完美头像",
    explain: "修改头像",
    number: 0,
    mumberMaxLen: 0,
    imgUrl: require("assets/images/medal/wmtx.png"),
  },
  {
    type: 0,
    name: "首次答题",
    explain: "首次答题",
    number: 0,
    mumberMaxLen: 0,
    imgUrl: require("assets/images/medal/scdt.png"),
  },
  {
    type: 0,
    name: "招募战友",
    explain: "成功邀请好友",
    number: 0,
    mumberMaxLen: 0,
    imgUrl: require("assets/images/medal/zmzy1.png"),
  },
  {
    type: 1,
    name: "招募战友",
    explain: "成功邀请好友满20",
    number: 10,
    mumberMaxLen: 20,
    imgUrl: require("assets/images/medal/zmzy2.png"),
  },
  {
    type: 1,
    name: "招募战友",
    explain: "成功邀请好友满50",
    number: 20,
    mumberMaxLen: 50,
    imgUrl: require("assets/images/medal/zmzy3.png"),
  },
  {
    type: 1,
    name: "招募战友",
    explain: "成功邀请好友满100",
    number: 50,
    mumberMaxLen: 100,
    imgUrl: require("assets/images/medal/zmzy4.png"),
  },
  {
    type: 1,
    name: "招募战友",
    explain: "成功邀请好友满300",
    number: 200,
    mumberMaxLen: 300,
    imgUrl: require("assets/images/medal/zmzy5.png"),
  },
];
