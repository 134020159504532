<template>
  <div class="content">
    <div class="content-herder">
      <div
        :class="active === 1 ? 'obtained active' : 'obtained'"
        @click="changeActive(1)"
      >
        已获得
      </div>
      <div
        :class="active === 2 ? 'not-obtained active' : 'not-obtained'"
        @click="changeActive(2)"
      >
        未获得
      </div>
      <span :class="`thread active${active}`"></span>
    </div>

    <div class="content-medal">
      <div
        :class="
          (index + 1) % 6 === 0 ? 'medal-item medal-item-right' : 'medal-item'
        "
        v-for="(item, index) in medalList"
        :key="index"
      >
        <img alt="奖章" :src="item.imgUrl" @click="changeMedalVisible(item)" />
        <div>{{ item.name }}</div>
        <div v-if="item.type" class="medal-progress">
          <el-progress
            v-bind:percentage="item | medalPercentage"
            :show-text="false"
          ></el-progress>
          <div>{{ item.number }}/{{ item.mumberMaxLen }}</div>
        </div>
      </div>
    </div>

    <div class="content-explain">
      <div class="content-explain-title">奖章说明</div>
    </div>

    <el-dialog :visible.sync="medalVisible" width="400px">
      <div class="dialog-main">
        <img
          class="medal-bgimg"
          src="@/assets/images/medal/medal-bg.png"
          alt="背景图片"
        />
        <div class="main-info">
          <div>{{ showData.name }}</div>
          <span>{{ showData.explain }}</span>
          <button @click="medalVisible = false">我知道了</button>
        </div>
        <img class="medal-img" :src="showData.imgUrl" alt="奖章" />
      </div>
    </el-dialog>
  </div>
</template>

<style src="./index.scss" lang="scss" scoped></style>
<style scoped>
.content >>> .el-dialog__header {
  display: none;
}
.content >>> .el-dialog__body {
  padding: 0;
  margin: 0;
  height: 0;
}
</style>

<script>
import { medalList } from "utlis/medal.js";
export default {
  filters: {
    medalPercentage(data) {
      return parseInt((data.number * 100) / data.mumberMaxLen);
    },
  },
  data() {
    return {
      active: 1,
      medalVisible: false,
      medalList: [],
      showData: {},
    };
  },
  methods: {
    changeActive(v) {
      this.active = v;
    },
    changeMedalVisible(data) {
      this.medalVisible = true;
      this.showData = data;
    },
  },
  created() {
    this.medalList = medalList;
    console.log(this.medalList);
  },
};
</script>